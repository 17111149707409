<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-md-8 col-md-offset-2">
        <b-card v-if="!whistleblowerFeatureEnabled">
          <h3>{{ $t('whistleblower.notEnabled.title') }}</h3>
          <div>{{ $t('whistleblower.notEnabled.description') }}</div>
          <b-button class="mt-5" variant="primary" to="/overview">
            {{ $t('whistleblower.buttons.done') }}
          </b-button>
        </b-card>
        <b-card
          border-variant="secondary"
          v-if="!sent && whistleblowerFeatureEnabled"
        >
          <b-card-header class="card-header-icon card-header-secondary">
            <div class="card-icon">
              <i class="material-icons">sports</i>
            </div>
            <h3 class="text-dark">{{ $t('whistleblower.title') }}</h3>
          </b-card-header>
          <b-card-body class="mt-3">
            <hr />
            <b-card-text class="h4 mb-4">
              {{
                $t('whistleblower.description', {
                  organizationName: organizationFullName
                })
              }}
            </b-card-text>
            <b-alert :show="true" variant="info">
              {{ $t('whistleblower.detailedDescription') }}
            </b-alert>

            <b-form-textarea
              id="messageText"
              v-model="messageText"
              :placeholder="$t('whistleblower.placeholder')"
              rows="28"
              max-rows="24"
              :state="validForm"
            ></b-form-textarea>
          </b-card-body>
          <b-card-footer class="mt-3">
            <b-button variant="secondary" to="/overview">
              {{ $t('whistleblower.buttons.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              :disabled="!validForm"
              @click="sendReport()"
            >
              {{ $t('whistleblower.buttons.send') }}
            </b-button>
          </b-card-footer>
        </b-card>
        <b-card v-if="sent">
          <span
            class="spinner-border spinner-border-lg center-spinner"
            v-if="loading"
          ></span>
          <b-card-text v-if="success && !loading">
            {{ $t('whistleblower.doneText') }}
          </b-card-text>
          <b-alert :show="true" variant="danger" v-if="error && !loading">{{
            error
          }}</b-alert>
          <b-button v-if="!loading" variant="secondary" to="/overview">
            {{ $t('whistleblower.buttons.done') }}
          </b-button>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { sendWhistleblowerReport } from '../services/AwsAmplifyService'
export default {
  name: 'Whistleblower',
  data() {
    return {
      sent: false,
      minCharacters: 10,
      maxCharacters: 2048,
      messageText: ''
    }
  },
  methods: {
    sendReport: function() {
      sendWhistleblowerReport(this.messageText, this.$store)
      this.sent = true
    }
  },
  computed: {
    whistleblowerFeatureEnabled() {
      // Or maybe allow everyone this.
      const orgData = JSON.parse(localStorage.orgData)
      return orgData.orgSettings.whistleblowerConfig
    },
    loading() {
      return this.$store.getters.whistleblowerLoading
    },
    success() {
      return this.$store.getters.whistleblowerSuccess
    },
    error() {
      return this.$store.getters.whistleblowerError
    },
    organizationFullName() {
      return this.$store.getters.userInfo.organizationFullName
    },
    validForm() {
      return (
        this.messageText.length >= this.minCharacters &&
        this.messageText.length <= this.maxCharacters
      )
    }
  }
}
</script>

<style scoped>
#messageText {
  border: 1px solid #ddd;
  padding-left: 10px;
  background: #eee;
}
</style>
